import { encodeObject } from "../helpers/request";

export async function sendEmail({ to, from, subject, body, body_is_html }) {
  return fetch(`${process.env.REACT_APP_ROSSWARE_API_BASE_URL}/v3/backend/sendTurnkeyEmail${encodeObject({
    to,
    from,
    subject,
    body,
    backend_key: process.env.REACT_APP_EMAIL_KEY,
    body_is_html,
  })}`)
    .then((response) => response.json())
    .then((data) => { return data });
}