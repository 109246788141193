import React from 'react'
import { Form, Button, Alert } from 'react-bootstrap'
import ReCAPTCHA from "react-google-recaptcha";
import { sendEmail } from '../../actions/send-email';
import { Helmet } from 'react-helmet';
import { MdCheck } from 'react-icons/md';
import ServerTerms from '../../components/text/ServerTerms';
import { Link } from 'react-router-dom';
import InputMask from 'react-input-mask';

export default class HostingOrder extends React.Component {
	state = {
		to: 'rsssupport@rossware.net',
		from: '',
		phone: '',
		company: '',
		dataCenter: 1,
		firstName: '',
		lastName: '',
		username: '',
		sendSuccess: false,
		captchaGood: false,
		serverOption: 1,
		termsRead: false,
		termsAgreed: false,
		migration: true
	}

	handleDataCenterOption(option) {
		this.setState({ dataCenter: option });
	}

	handleMigration(option) {
		this.setState({ migration: option });
	}

	onChangeCaptcha = (event) => {
		console.log(event)
		this.setState({ captchaGood: event });
	}

	handleChangeOption(option) {
		this.setState({ serverOption: option });
	}

	handleCheckbox = (e) => {
		this.setState({ termsAgreed: e.target.checked });
		console.log(e.target.checked)
	}

	onChangeForm = (e) => {
		this.setState({ [e.target.name]: e.target.value })
	}

	async handleSubmit() {
		const { to, from, firstName, lastName, company, phone, serverOption, dataCenter, migration, username } = this.state;
		const body = `A new Rossware Hosted Server has been orderd. Below are the details. \n\nTerms agreed to by: ${firstName} ${lastName} \nCompany: ${company} \nPhone: ${phone} \nEmail: ${from} \nDesired username: ${username} \nData center: ${dataCenter === 1 ? 'US West' : 'US East'} \nServer tier: ${serverOption} \n\n Migration requested: ${migration ? 'Yes' : 'No'}`;
		const subject = `Server Hosting Order from ${company}`;
		this.setState({ sendSuccess: true });
		await sendEmail({ to, from, subject, body });
	}

	detectScoll = (e) => {
		const bottom = e.target.scrollHeight - Math.floor(e.target.scrollTop) === e.target.clientHeight;
		if (bottom) {
			this.setState({ termsRead: true });
		}
	}

	render() {
		const { from, firstName, lastName, company, phone, captchaGood, sendSuccess, serverOption, dataCenter, termsRead, termsAgreed, migration } = this.state;
		var disabled = true;
		if (from && firstName && lastName && company && phone && termsAgreed && captchaGood) {
			disabled = false;
		}
		let amount;
		switch (serverOption) {
			case 1:
				amount = 199;
				break;
			case 2:
				amount = 299;
				break;
			case 3:
				amount = 449;
				break;
			case 4:
				amount = 699;
				break;
			case 5:
				amount = 1449;
				break;
			default:
				break;
		}
		const migrationFee = 149;
		return (
			<div>
				<Helmet>
					<title>Hosting Order - Rossware Software, LLC.</title>
					<meta name="fragment" content="!"></meta>
					<meta name="description" content="Server hosting order. Place your order on the form and we will start building your virtual private server." />
					<meta property="og:title" content="Hosting Order  - Rossware Software, LLC." />
					<meta property="og:image" content="%PUBLIC_URL%/logo512.png" />
				</Helmet>
				<div className="pt-6">
					<div className="container-sm">
						<h1 className="mb-4">Server Hosting Signup</h1>
						<p className="lead">Please complete the form below to signup for server hosting.</p>
					</div>
				</div>
				<div className="container-sm py-5">
					{sendSuccess &&
						<Alert variant="success" className="py-4">
							<Alert.Heading>Thanks for signing up!</Alert.Heading>
							<hr />
							<div>Please allow up to 24hrs for us to fulfill your request.</div>
						</Alert>
					}
					{!sendSuccess &&
						<Form className="py-2">
							<div className="form-group mb-4">
								<h4 className="my-3">Company name and/or Rossware UserID number <code>*</code></h4>
								<Form.Control type="email" placeholder="John Doe Services / 1234" name="company" onChange={this.onChangeForm} />
							</div>
							<div className="grid-2-col">
								<div className="form-group mb-4">
									<h4 className="my-2">First name <code>*</code></h4>
									<Form.Control type="text" placeholder="John" name="firstName" onChange={this.onChangeForm} />
								</div>
								<div className="form-group mb-4">
									<h4 className="my-2">Last name <code>*</code></h4>
									<Form.Control type="text" placeholder="Doe" name="lastName" onChange={this.onChangeForm} />
								</div>
							</div>
							<div className="grid-2-col">
								<div className="form-group mb-4">
									<h4 className="my-2">Email address <code>*</code></h4>
									<Form.Control type="email" name="from" placeholder="name@example.com" onChange={this.onChangeForm} />
								</div>
								<div className="form-group mb-4">
									<h4 className="my-2">Phone number <code>*</code></h4>
									<Form.Control type="text" placeholder="(000)000-0000" name="phone" onChange={this.onChangeForm} as={ InputMask } mask="(999) 999-9999" />
								</div>
							</div>

							<div className="form-group mb-4">
								<h4 className="my-2">Desired Administrator account username <code>*</code></h4>
								<Form.Control type="text" name="username" placeholder="AlexR" onChange={this.onChangeForm} />
							</div>

							<h4 className="my-2">Choose your tier <code>*</code></h4>
							<div className="grid-2-col pb-4">
								<div
									className={`pricingPlanOption ${serverOption === 1 && 'pricingPlanOptionActive'}`}
									onClick={() => this.handleChangeOption(1)}>
									<div className="lead text-dark mb-0 font-weight-bolder">Tier 1 - $199/month</div>
									<div className="small">Users included: 2, Recommended: 1-3, CPU Cores: 2, Ram: 4GB, Storage: 150GB</div>
									<div className="plan-circle-check"><MdCheck /></div>
								</div>
								<div
									className={`pricingPlanOption ${serverOption === 2 && 'pricingPlanOptionActive'}`}
									onClick={() => this.handleChangeOption(2)}>
									<div className="lead text-dark mb-0 font-weight-bolder">Tier 2 - $299/month</div>
									<div className="small">Users included: 7, Recommended: 3-7, Cores: 2, Ram: 8GB, Storage: 150GB</div>
									<div className="plan-circle-check"><MdCheck /></div>
								</div>
								<div className={`pricingPlanOption ${serverOption === 3 && 'pricingPlanOptionActive'}`}
									onClick={() => this.handleChangeOption(3)}>
									<div className="lead text-dark mb-0 font-weight-bolder">Tier 3 - $449/month</div>
									<div className="small">Users Included: 10, Recommended: 7-12, Cores: 4, Ram: 16GB, Storage: 150GB</div>
									<div className="plan-circle-check"><MdCheck /></div>
								</div>
								<div
									className={`pricingPlanOption ${serverOption === 4 && 'pricingPlanOptionActive'}`}
									onClick={() => this.handleChangeOption(4)}>
									<div className="lead text-dark mb-0 font-weight-bolder">Tier 4 - $699/month</div>
									<div className="small">Users Included: 15, Recommended: 12-25, Cores: 8, Ram: 32GB, Storage: 150GB</div>
									<div className="plan-circle-check"><MdCheck /></div>
								</div>
								<div
									className={`pricingPlanOption ${serverOption === 5 && 'pricingPlanOptionActive'}`}
									onClick={() => this.handleChangeOption(5)}>
									<div className="lead text-dark mb-0 font-weight-bolder">Tier 5 - $1499/month</div>
									<div className="small">Users Included: 30, Recommended: 25+, Cores: 16, Ram: 64GB, Storage: 150GB</div>
									<div className="plan-circle-check"><MdCheck /></div>
								</div>
							</div>

							<h4 className="my-2">Choose the closest datacenter <code>*</code></h4>
							<div className="grid-2-col pb-3">
								<div
									className={`pricingPlanOption ${dataCenter === 1 && 'pricingPlanOptionActive'}`}
									onClick={() => this.handleDataCenterOption(1)}>
									<div className="lead text-dark mb-0 font-weight-bolder">US West</div>
									<div className="small">Oregon or California datacenter</div>
									<div className="plan-circle-check"><MdCheck /></div>
								</div>
								<div
									className={`pricingPlanOption ${dataCenter === 2 && 'pricingPlanOptionActive'}`}
									onClick={() => this.handleDataCenterOption(2)}>
									<div className="lead text-dark mb-0 font-weight-bolder">US East</div>
									<div className="small">Ohio datacenter</div>
									<div className="plan-circle-check"><MdCheck /></div>
								</div>
							</div>

							<h4 className="my-2">Rossware data migration <code>*</code></h4>
							<small className="d-block pb-3">If you have existing Rossware data on a local machine or other server you are planning on moving to this new server  <b>it is highly recommended you allow us to migrate the data for you</b>. This is to ensure you have as little downtime as possible and do not risk data corruption. If you have questions please <Link to="/contact">contact us.</Link></small>
							<div className="grid-2-col pb-3">
								<div
									className={`pricingPlanOption ${migration && 'pricingPlanOptionActive'}`}
									onClick={() => this.handleMigration(true)}>
									<div className="lead text-dark mb-0 font-weight-bolder">Migrate my data for me</div>
									<div className="small">$149 one time charge</div>
									<div className="plan-circle-check"><MdCheck /></div>
								</div>
								<div
									className={`pricingPlanOption ${!migration && 'pricingPlanOptionActive'}`}
									onClick={() => this.handleMigration(false)}>
									<div className="lead text-dark mb-0 font-weight-bolder">No thank you</div>
									<div className="small">$0</div>
									<div className="plan-circle-check"><MdCheck /></div>
								</div>
							</div>

							<h4 className="my-2">Summary</h4>
							<div>One time setup fee: $100</div>
							<div>One time data migration fee: ${migration ? migrationFee : 0}</div>
							<div>Monthly server subscription: ${amount}/month</div>
							<div className="pt-2">
								<h4 className="my-3">Read and agree to terms <code>*</code></h4>
								<div className="server-terms" onScroll={this.detectScoll}>
									<ServerTerms />
								</div>
								<Form.Group className="my-3">
									<Form.Check type="checkbox" disabled={!termsRead} onChange={e => this.handleCheckbox(e)} label="I have read and agree to all the terms in the service agreement above and I am a representative of the listed company (scroll to read all of terms to continue)" />
								</Form.Group>
							</div>
							<div className="my-3">
								<ReCAPTCHA
									sitekey="6LcjVFsUAAAAAAT0mrnR8ONYHMxXbX5a37OGH8LU"
									onChange={this.onChangeCaptcha}
								/>
							</div>
							<Button disabled={disabled} onClick={() => this.handleSubmit()} className="btn-block">Sign up</Button>
						</Form>
					}
				</div>
			</div>
		)
	}
}
